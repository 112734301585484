import { useMemo } from 'react';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { de, enGB as en, fr, it } from 'date-fns/locale';
import cx from 'classnames';

import Tooltip from 'components/ui/Tooltip';
import Countdown from 'components/ui/Countdown';

import { useAdditionalInfo, useAuctionMetrics } from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import { formatNumber } from 'utils/utils';

import { AuctionStatus } from 'types/enum';

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  isPendingWithLowBid: boolean;
  openLowBidHintModal: () => void;
};

const AuctionStatusBox: React.FC<Props> = ({
  isPendingWithLowBid,
  openLowBidHintModal,
}) => {
  const { t, locale } = useCmsData();
  const { data: additionalInfo, refetch, submissionId } = useAdditionalInfo();
  const { data: auctionMetrics } = useAuctionMetrics(submissionId || '', {
    enabled: additionalInfo?.status === AuctionStatus.Active,
  });

  const {
    auction: {
      counterOffer,
      counterOfferAccepted,
      bids,
      visits,
      startsAt,
      endsAt,
      highestBid,
    },
    status,
  } = { ...additionalInfo! };

  const renderTopStatusLabel = () => {
    switch (status) {
      case AuctionStatus.InReview:
        return (
          <p className="bg-warning-tint inline-block p-1 font-bold text-xs">
            {t('dashboard_status_in_review')}
          </p>
        );
      case AuctionStatus.Scheduled:
        return (
          <p className="bg-highlight inline-block p-1 font-bold text-xs">
            {t('dashboard_status_scheduled')}
          </p>
        );
      case AuctionStatus.Active:
        return (
          <p className="bg-secondary-light inline-block p-1 font-bold text-xs">
            {t('dashboard_status_active')}
          </p>
        );
      case AuctionStatus.PendingDecision:
        return (
          <p className="bg-warning-tint inline-block p-1 font-bold text-xs">
            {t('dashboard_status_pending_decision')}
          </p>
        );
      case AuctionStatus.PendingCounterOffer:
        return (
          <p className="bg-highlight inline-block p-1 font-bold text-xs">
            {t('dashboard_status_pending_counter_offer')}
          </p>
        );
      case AuctionStatus.Accepted:
        return counterOfferAccepted ? (
          <p className="bg-secondary-light inline-block p-1 font-bold text-xs">
            {t('dashboard_status_accepted')}
          </p>
        ) : (
          <p className="bg-border-light inline-block p-1 font-bold text-xs">
            {t('dashboard_status_ended')}
          </p>
        );
      default:
        return (
          <p className="bg-border-light inline-block p-1 font-bold text-xs">
            {t('dashboard_status_ended')}
          </p>
        );
    }
  };

  const isAuctionEnded = useMemo(
    () => (endsAt ? new Date() >= new Date(endsAt) : false),
    [endsAt]
  );
  const endedWithNoBids =
    isAuctionEnded && status !== AuctionStatus.Active && !bids;

  return (
    <>
      {renderTopStatusLabel()}
      <div className="border rounded border-light">
        <div className="flex justify-between items-start p-4">
          <div className="flex items-center flex-col border-r border-light h-full pr-3">
            <div className="flex-col md:flex-row flex items-center">
              <TimeIcon className="text-border size-6" />
              <div className="md:ml-2 text-center md:text-left font-bold">
                {status === AuctionStatus.InReview ? (
                  '-'
                ) : status === AuctionStatus.Scheduled ? (
                  <>
                    {t('dashboard_status_box_starts_in')}{' '}
                    <Countdown
                      date={new Date(startsAt!)}
                      onComplete={refetch}
                    />
                  </>
                ) : status === AuctionStatus.Active && !isAuctionEnded ? (
                  <Countdown date={new Date(endsAt!)} onComplete={refetch} />
                ) : status === AuctionStatus.Active && isAuctionEnded ? (
                  t('dashboard_status_box_extended')
                ) : (
                  t('dashboard_status_box_ended')
                )}
              </div>
            </div>
            <div className="text-sm text-light-shade">
              {!!startsAt &&
                format(startsAt, 'dd MMMM yyyy HH:mm', {
                  locale: { de, en, fr, it }[locale],
                })}
            </div>
          </div>
          <div className="flex items-center flex-col px-3">
            <div className="flex-col md:flex-row flex items-center">
              <BellIcon className="text-border size-6" />
              <div className="md:ml-2 text-center md:text-left">
                {parse(
                  t('dashboard_status_box_bids').replace(
                    '{bids}',
                    (auctionMetrics?.bids || bids || 0).toString()
                  )
                )}
              </div>
            </div>
            <div className="text-sm text-light-shade">
              {t('dashboard_status_box_bids_hint')}
            </div>
          </div>
          <div className="flex items-center flex-col border-l border-light h-full pl-3">
            <div className="flex-col md:flex-row flex items-center">
              <EyeIcon className="text-border size-6" />
              <div className="md:ml-2 text-center md:text-left">
                {parse(
                  t('dashboard_status_box_visits').replace(
                    '{visits}',
                    (auctionMetrics?.visits || visits || 0).toString()
                  )
                )}
              </div>
            </div>
            <div className="text-sm text-light-shade">
              {t('dashboard_status_box_visits_hint')}
            </div>
          </div>
        </div>
        <hr className="border-none h-[1px] bg-light" />
        <div
          className={cx(
            'flex flex-col items-center justify-center p-6 min-h-[112px]',
            {
              '!text-border': counterOfferAccepted,
            }
          )}
        >
          <div className="flex items-center justify-center">
            <span
              className={cx(
                'text-center',
                isPendingWithLowBid && 'text-danger font-semibold'
              )}
            >
              {!isPendingWithLowBid
                ? t('dashboard_status_box_highest_bid_label')
                : t('dashboard_status_box_low_bid_label')}
            </span>
            <Tooltip
              color="var(--color-background)"
              title={
                !isPendingWithLowBid
                  ? t('dashboard_status_box_highest_bid_tooltip')
                  : t('dashboard_status_box_low_bid_tooltip')
              }
            >
              <InfoIcon
                className={cx(
                  'ml-1 size-5 cursor-pointer',
                  isPendingWithLowBid ? 'text-danger' : 'text-border'
                )}
              />
            </Tooltip>
          </div>
          {status === AuctionStatus.InReview && (
            <span className="mt-1 text-sm text-light-shade">
              {t('dashboard_status_box_info_msg_in_review')}
            </span>
          )}
          {(status === AuctionStatus.Scheduled ||
            status === AuctionStatus.Active) && (
            <span className="text-sm text-light-shade">
              {t('dashboard_status_box_info_msg_displayed_after_auction_ended')}
            </span>
          )}
          {endedWithNoBids && (
            <span className="text-sm font-bold">
              {t('dashboard_status_box_info_msg_no_bids')}
            </span>
          )}
          {status !== AuctionStatus.InReview &&
            status !== AuctionStatus.Scheduled &&
            status !== AuctionStatus.Active &&
            !endedWithNoBids && (
              <>
                {highestBid > 0 && (
                  <div className="text-center">
                    <div className="text-3xl font-bold">
                      {formatNumber(highestBid, {
                        price: true,
                        hiddenFloat: false,
                      })}
                    </div>
                    {isPendingWithLowBid && (
                      <p
                        className="text-tertiary cursor-pointer"
                        onClick={openLowBidHintModal}
                      >
                        {t('dashboard_status_box_low_bid_action_link')}
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
        </div>
        {(status === AuctionStatus.PendingCounterOffer ||
          typeof counterOfferAccepted === 'boolean') &&
          counterOffer && (
            <div className="text-center py-6 border-t border-light">
              <div className="flex items-center justify-center">
                <span>{t('dashboard_status_box_counter_offer_label')}</span>
                <Tooltip
                  color="var(--color-background)"
                  title={t('dashboard_status_box_counter_offer_tooltip')}
                >
                  <InfoIcon className="text-border ml-1 size-5 cursor-pointer" />
                </Tooltip>
              </div>
              <div className="text-3xl font-bold">
                {formatNumber(counterOffer, {
                  price: true,
                  hiddenFloat: false,
                })}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default AuctionStatusBox;
