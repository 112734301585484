import { useMutation, useQuery } from 'react-query';
import { PickerOptions, PickerResponse } from 'filestack-js';

import {
  getAdditionalInfo,
  getAttributes,
  getAuctionMetrics,
  getDealerContacts,
  saveChanges,
} from 'api/api';
import { uploadFile } from 'services/filestack';
import { useCmsData } from 'contexts/CmsDataProvider';

export const useAdditionalInfo = () => {
  const submissionId = window.location.pathname
    ?.split('/u/')
    ?.pop()
    ?.split('/')[0];

  return {
    ...useQuery('additionalInfo', () => getAdditionalInfo(submissionId!), {
      enabled: !!submissionId,
    }),
    submissionId,
  };
};

export const useAttributes = () => useQuery('attributes', getAttributes);

export const useDealerContacts = (
  submissionId: string,
  { enabled = false }: { enabled: boolean }
) =>
  useQuery(
    ['dealerContacts', submissionId],
    () => getDealerContacts(submissionId),
    {
      enabled,
      retry: false,
    }
  );

export const useAuctionMetrics = (
  submissionId: string,
  { enabled = false }: { enabled: boolean }
) =>
  useQuery(
    ['auctionMetrics', submissionId],
    () => getAuctionMetrics(submissionId || ''),
    {
      enabled,
    }
  );

type FileType = 'regDoc' | 'photo' | 'additionalPhoto';

export const useUploadFile = () => {
  const { locale } = useCmsData();
  const { submissionId, refetch } = useAdditionalInfo();

  const mutation = useMutation('uploadFile', (data: any) => saveChanges(data), {
    onSuccess: () => refetch(),
    onError: () => alert("Couldn't upload the image"),
  });

  const { mutate, mutateAsync } = mutation;

  const onUploadDone = async (
    res: PickerResponse,
    { type, fileIndex }: { type: FileType; fileIndex?: number }
  ) => {
    if (type === 'regDoc') {
      mutate({
        registration_documents: [ { url: res.filesUploaded[0].url } ],
        submission_id: submissionId,
      });
    } else if (type === 'photo') {
      mutate( {
        photo: { url: res.filesUploaded[0].url, index: fileIndex },
        submission_id: submissionId,
      } );
    } else if (type === 'additionalPhoto') {
      for ( let i = 0; i < res.filesUploaded.length; i++ ) {
        const url = res.filesUploaded[ i ].url;

        await mutateAsync( {
          additional_photo: { url },
          submission_id: submissionId,
        } );
      }
    }
  };

  const handleUploadFile = ({
    options,
    type,
    fileIndex,
    recipe,
  }: {
    options: PickerOptions;
    type: FileType;
    fileIndex?: number;
    recipe?: object[];
  }) =>
    uploadFile({
      lang: locale,
      onUploadDone: (res) => onUploadDone(res, { type, fileIndex }),
      onFileSelected: async (file) => ({
        ...file,
        filename: recipe ? btoa(JSON.stringify(recipe) ).replace(/=/g, '_') : file.filename,
      }),
      ...options,
    });

  const removeFile = ({
    type,
    fileIndex,
  }: {
    type: FileType;
    fileIndex?: number;
  }) => {
    if (type === 'regDoc') {
      mutate({
        registration_documents: ['-'],
        submission_id: submissionId,
      });
    } else if (type === 'photo') {
      mutate({
        photo: { url: '-', index: fileIndex },
        submission_id: submissionId,
      });
    } else if (type === 'additionalPhoto') {
      mutate({
        additional_photo: { url: '-', index: fileIndex },
        submission_id: submissionId,
      });
    }
  };

  return { ...mutation, uploadFile: handleUploadFile, removeFile };
};
