import { Fragment, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import _ from 'lodash';

import Question from './Question';
import Textarea from 'components/ui/Textarea';
import Dropdown from 'components/ui/Dropdown';
import Button from 'components/ui/Button';
import Fade from 'components/ui/Fade';

import { trackEvent } from 'services/plausible';
import { useAdditionalInfo } from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import { saveChanges, setAdditionalDescription } from 'api/api';

import type { AdditionalInfo } from 'types/types';

type Props = {
  className?: string;
  goNext: () => any;
};

const QUESTIONS_PER_PAGE = 4;

const Questions: React.FC<Props> = ({ className, goNext }) => {
  const { data: cmsData, t, getOptions, locale } = useCmsData();
  const { data: additionalInfo, submissionId } = useAdditionalInfo();

  const [serviceCheckbook, setServiceCheckbook] = useState<
    AdditionalInfo['vehicleInfo']['serviceCheckbook']
  >(additionalInfo?.vehicleInfo.serviceCheckbook || null);
  const [paint, setPaint] = useState<AdditionalInfo['vehicleInfo']['paint']>(
    additionalInfo?.vehicleInfo.paint || null
  );
  const [exteriorColor, setExteriorColor] = useState<
    AdditionalInfo['transfer']['exteriorColor']
  >(additionalInfo?.transfer.exteriorColor || null);
  const [interiorColor, setInteriorColor] = useState<
    AdditionalInfo['transfer']['interiorColor']
  >(additionalInfo?.transfer.interiorColor || null);
  const [interiorType, setInteriorType] = useState<
    AdditionalInfo['transfer']['interiorType']
  >(additionalInfo?.transfer.interiorType || null);
  const [answers, setAnswers] = useState<AdditionalInfo['answers']>(
    additionalInfo?.answers && cmsData
      ? _.pick(
          additionalInfo.answers,
          cmsData.questions.map((el) => el.key)
        )
      : {}
  );
  const [additionalDesc, setAdditionalDesc] = useState<string | undefined>(
    additionalInfo?.additionalDescription?.original || undefined
  );

  const { mutate, isLoading } = useMutation(
    () =>
      Promise.all([
        saveChanges({
          submission_id: submissionId,
          service_checkbook: serviceCheckbook,
          paint,
          exterior_color: exteriorColor,
          interior_color: interiorColor,
          interior_type: interiorType,
          answers,
        }),
        (!additionalInfo?.additionalDescription?.[locale] && !additionalDesc) ||
        additionalInfo?.additionalDescription?.[locale] === additionalDesc
          ? true
          : setAdditionalDescription({
              submission_id: submissionId!,
              content: additionalDesc || '',
              lang: locale,
            }),
      ]),
    {
      onSuccess: () => {
        trackEvent('as24-step3-detailed-questions');
        goNext();
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const onChange = (key: string, value: boolean | null) => {
    if (!answers) {
      return setAnswers({ [key]: value } as any);
    }

    setAnswers({ ...answers, [key]: value });
  };

  const serviceCheckbookOptions = useMemo(
    () => getOptions('questions_service_checkbook'),
    [getOptions]
  );
  const paintOptions = useMemo(
    () => getOptions('questions_paint'),
    [getOptions]
  );
  const exteriorColorOptions = useMemo(
    () => getOptions('questions_exterior_color'),
    [getOptions]
  );
  const interiorColorOptions = useMemo(
    () => getOptions('questions_interior_color'),
    [getOptions]
  );
  const interiorTypeOptions = useMemo(
    () => getOptions('questions_interior_type'),
    [getOptions]
  );

  return (
    <div className={className}>
      <p>
        {t('questions_progress')
          .replace(
            '{answered}',
            (answers && cmsData
              ? cmsData.questions.filter((el) => answers[el.key] !== undefined)
                  .length +
                [
                  serviceCheckbook,
                  paint,
                  exteriorColor,
                  interiorColor,
                  interiorType,
                ].filter((el) => el).length
              : 0
            ).toString()
          )
          .replace(
            '{total}',
            (cmsData?.questions.length
              ? cmsData.questions.length + 5
              : 0
            ).toString()
          )}
      </p>
      <div className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-y-12 md:grid-cols-3 lg:grid-cols-4 gap-x-6">
        <Dropdown
          label={t('questions_service_checkbook_title')}
          options={serviceCheckbookOptions}
          value={serviceCheckbook}
          onChange={(val) => setServiceCheckbook(val as string)}
          placeholder={t('questions_service_checkbook_placeholder')}
          hint={t('questions_service_checkbook_hint')}
          tooltip={t('questions_service_checkbook_tooltip')}
        />
        <Dropdown
          label={t('questions_paint_title')}
          options={paintOptions}
          value={paint}
          onChange={(val) => setPaint(val as string)}
          placeholder={t('questions_paint_placeholder')}
          hint={t('questions_paint_hint')}
          tooltip={t('questions_paint_tooltip')}
        />
        <Dropdown
          label={t('questions_exterior_color_title')}
          options={exteriorColorOptions}
          value={exteriorColor}
          onChange={(val) => setExteriorColor(val as string)}
          placeholder={t('questions_exterior_color_placeholder')}
          hint={t('questions_exterior_color_hint')}
          tooltip={t('questions_exterior_color_tooltip')}
        />
        <Dropdown
          label={t('questions_interior_color_title')}
          options={interiorColorOptions}
          value={interiorColor}
          onChange={(val) => setInteriorColor(val as string)}
          placeholder={t('questions_interior_color_placeholder')}
          hint={t('questions_interior_color_hint')}
          tooltip={t('questions_interior_color_tooltip')}
        />
        <Dropdown
          label={t('questions_interior_type_title')}
          options={interiorTypeOptions}
          value={interiorType}
          onChange={(val) => setInteriorType(val as string)}
          placeholder={t('questions_interior_type_placeholder')}
          hint={t('questions_interior_type_hint')}
          tooltip={t('questions_interior_type_tooltip')}
        />
      </div>
      <div className="mt-12 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-y-12 md:grid-cols-3 lg:grid-cols-4 gap-x-6">
        {cmsData?.questions
          .slice(
            0,
            (Math.floor(Object.keys(answers).length / QUESTIONS_PER_PAGE) + 1) *
              QUESTIONS_PER_PAGE
          )
          .map((question, i) => (
            <Fragment key={question.id}>
              <Fade>
                <Question
                  label={question[locale]}
                  value={(answers as any)?.[question.key]}
                  isOptional={question.optionalAnswer}
                  onChange={(val) => onChange(question.key, val)}
                  tooltip={t(`question_${question.key}_tooltip`, true)}
                />
              </Fade>
              {i === cmsData.questions.length - 1 && (
                <Fade>
                  <Textarea
                    label={t('questions_additional_desc_title')}
                    value={additionalDesc}
                    maxLength={450}
                    onChange={setAdditionalDesc}
                    tooltip={t('questions_additional_desc_tooltip')}
                    placeholder={t('questions_additional_desc_placeholder')}
                  />
                </Fade>
              )}
            </Fragment>
          ))}
      </div>
      <div className="mt-6 pb-20 flex justify-end">
        <Button
          className="w-full sm:w-auto"
          label={t('questions_next_btn')}
          onClick={mutate}
          isDisabled={
            !answers ||
            !cmsData ||
            !_.every(
              cmsData.questions.map((el) => el.key),
              (key) => _.has(answers, key)
            ) ||
            ![
              serviceCheckbook,
              paint,
              exteriorColor,
              interiorColor,
              interiorType,
            ].every((el) => el)
          }
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Questions;
