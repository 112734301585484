import cx from 'classnames';

import { useCmsData } from 'contexts/CmsDataProvider';

import type { AdditionalInfo } from 'types/types';

type Props = {
  answers: AdditionalInfo['answers'];
  additionalDescription?: AdditionalInfo['additionalDescription']['en'];
  className?: string;
};

const AnswerList: React.FC<Props> = ({
  answers,
  additionalDescription,
  className,
}) => {
  const { t } = useCmsData();

  return (
    <div className={cx('w-full mt-2', className)}>
      <ul className="w-full">
        {!!answers &&
          Object.entries(answers).map(([key, value], index) => (
            <li
              key={key}
              className={cx('flex justify-between p-1', {
                'bg-gray': index % 2 === 0,
                'bg-background': index % 2 !== 0,
              })}
            >
              <span>{t(key)}</span>
              <span className="ml-2 font-bold">
                {value === true
                  ? t('questions_answer_yes')
                  : value === false
                  ? t('questions_answer_no')
                  : value === null
                  ? t('questions_answer_not_available')
                  : value}
              </span>
            </li>
          ))}
      </ul>
      {additionalDescription && (
        <div
          className={cx('p-1', {
            'bg-gray': Object.keys(answers).length % 2 === 0,
            'bg-background': Object.keys(answers).length % 2 !== 0,
          })}
        >
          <b>{t('offer_additional_description')}</b> {additionalDescription}
        </div>
      )}
    </div>
  );
};

export default AnswerList;
