import { useEffect, useState } from 'react';

import ImageUploader from 'components/ui/ImageUploader';
import Button from 'components/ui/Button';

import { useCmsData } from 'contexts/CmsDataProvider';
import { trackEvent } from 'services/plausible';
import { useAdditionalInfo, useUploadFile } from 'hooks/queries';
import usePdfThumbnail from 'hooks/usePdfThumbnail';
import { isPdfFile } from 'utils/utils';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import regDocThumbnail from 'assets/thumbnails/registration_doc.jpg';

type Props = {
  className?: string;
  goBack: () => any;
  goNext: () => any;
};

const RegistrationDoc: React.FC<Props> = ({ className, goBack, goNext }) => {
  const [isPdfThumbnail, setIsPdfThumbnail] = useState(false);

  const { t } = useCmsData();
  const { uploadFile, removeFile } = useUploadFile();
  const { data } = useAdditionalInfo();
  const { thumbnail: pdfThumbnail } = usePdfThumbnail(
    data?.registrationDocuments?.[0]?.url || '',
    {
      enabled: isPdfThumbnail,
    }
  );

  useEffect(() => {
    (async () => {
      setIsPdfThumbnail(false);
      if (data?.registrationDocuments?.length) {
        const isPdf = await isPdfFile(data.registrationDocuments[0].url);

        if (isPdf) {
          setIsPdfThumbnail(true);
        }
      }
    })();
  }, [data]);

  return (
    <div className={className}>
      <div className="flex justify-center">
        <ImageUploader
          title={t('upload_registration_document_title')}
          description={t('upload_registration_document_description')}
          image={
            (isPdfThumbnail
              ? pdfThumbnail
              : data?.registrationDocuments?.[0]?.url) || ''
          }
          thumbnail={regDocThumbnail}
          btnText={t('upload_button')}
          onUpload={() =>
            uploadFile({
              options: {
                accept: ['.pdf', 'image/*'],
              },
              type: 'regDoc',
              recipe: [{ name: 'autoscale' }],
            }).then(() => trackEvent('as24-step4-upload-registration'))
          }
          onRemove={() =>
            removeFile({
              type: 'regDoc',
            })
          }
        />
      </div>
      <div className="mt-6 pb-20 flex flex-col gap-y-3 sm:flex-row justify-between">
        <Button
          className="!bg-background !border !border-dark"
          label={t('reg_doc_prev_btn')}
          iconSlot="start"
          icon={<ArrowLeftIcon className="mr-1 size-6" />}
          onClick={goBack}
        />
        <Button
          label={t('reg_doc_next_btn')}
          onClick={goNext}
          isDisabled={!data?.registrationDocuments?.[0]?.url}
        />
      </div>
    </div>
  );
};

export default RegistrationDoc;
